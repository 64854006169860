import styled, { css } from "styled-components"

export const StyledPostDescription = styled.p`
  font-family: ${props => props.theme.fontFamily.postDescription};
  font-size: ${props => props.theme.fontSize.s};
  font-weight: 400;
  color: ${props => props.theme.colors.gray};
  width: 250px;
  margin: 10px 0;
  ${({ overflowHidden }) =>
    overflowHidden &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`
