import { images } from "./images"
// import { languageToShow } from "../Templates/Settings/options/languageHandler"
import { pl } from "./languages/PL"
import { en } from "./languages/EN"

const selectedLanguage = e => {
  if (e === "PL") {
    return pl
  } else if (e === "EN") {
    return en
  }
}
const windowGlobal = typeof window !== "undefined" && window
const languageToShow = selectedLanguage(
  windowGlobal.localStorage?.getItem("language") || "EN"
)

const res = languageToShow || pl

export const pageContent = {
  hello: {
    header: res.hello.header,
    subheader: res.hello.subheader,
    img: images.hi2,
  },
  aboutMe: {
    header: res.aboutMe.header,
    subheader: res.aboutMe.subheader,
    img: images.bans,
    paragraphes: res.aboutMe.paragraphes,
  },
  myFreeTime: {
    header: res.myFreeTime.header,
    activities: res.myFreeTime.activities,
  },
  blog: {
    header: res.blog.header,
    postTitle: res.blog.postTitle,
    postDescription: res.blog.postDescription,
  },
  contact: {
    header: res.contact.header,
    subheader: res.contact.subheader,
    img: images.hej1,
    contactPictures: res.contact.contactPictures,
  },
  footer: {
    footerContent: res.footer.footerContent,
  },
  buttons: {
    navButtons: res.buttons.navButtons,
    showPostButton: res.buttons.showPostButton,
    loadMorePostButton: res.buttons.loadMorePostButton,
    checkAllPosts: res.buttons.checkAllPosts,
  },
}
