import styled from "styled-components"

export const StyledPostTitle = styled.h4`
  margin-top: 10px;
  font-family: ${props => props.theme.fontFamily.postTitle};
  font-size: ${props => props.theme.fontSize.m};
  font-weight: 600;
  color: ${props => props.theme.colors.gray};
  text-align: center;
  text-transform: uppercase;
`
