import React, { useContext } from "react"
import News from "../Components/News"
import FooterSection from "../sections/footerSection"
import { pageContent } from "../utils/pageContent"
import { StyledHeader } from "../Components/styled-components/index.styledComponents"
import { StoreContext } from "../store/StoreProvider"

const Articles = ({ data, showPostButton }) => {
  const { language } = useContext(StoreContext)
  const articles = data.allDatoCmsArticle.edges.filter(
    article =>
      article.node.language?.toLowerCase() === String(language).toLowerCase()
  )
  const news = articles.map(article => (
    <News
      className="news-card"
      key={article.node.title}
      showPostButton={showPostButton}
      postTitle={article.node.title}
      postDescription={article.node.description}
      thumbnail={article.node.thumbnail}
      link={article.node.slug}
    />
  ))
  return (
    <section className="blog-section articles-page">
      <StyledHeader alignCenter>{pageContent.blog.header}</StyledHeader>
      <article className="blog-section__container">
        <section className="blog-section__news-container">{news}</section>
      </article>

      <FooterSection />
    </section>
  )
}

export default Articles
