import React, { useEffect } from "react"
import { Link } from "gatsby"
import Button from "../Components/Button"
import PropTypes from "prop-types"
import { cardHoverAnimation } from "../animations/hover/cardHover"
import {
  StyledNewsCard,
  StyledImage,
} from "./styled-components/index.styledComponents"
import { StyledPostTitle } from "./styled-components/PostTitle.style"
import { StyledPostDescription } from "./styled-components/PostDescription.style"

const News = ({
  postTitle,
  showPostButton,
  thumbnail,
  link,
  postDescription,
  blogPage,
  className,
}) => {
  useEffect(() => {
    cardHoverAnimation()
  }, [])

  return (
    <StyledNewsCard className={className} blogPage={blogPage}>
      <Link to={link}>
        {thumbnail ? (
          <StyledImage
            blogSection
            src={thumbnail.url ? thumbnail.url : null}
          ></StyledImage>
        ) : (
          <div style={{ height: "200px", width: "500px" }}></div>
        )}
      </Link>
      <Link to={link}>
        <StyledPostTitle blogSection lightColor>
          {postTitle}
        </StyledPostTitle>
      </Link>
      <StyledPostDescription overflowHidden>
        {postDescription}
      </StyledPostDescription>
      <Button
        className="button-hover"
        link={link}
        buttonText={showPostButton}
        white
      />
    </StyledNewsCard>
  )
}

export default News

News.propTypes = {
  postTitle: PropTypes.string,
  button: PropTypes.string,
  thumbnail: PropTypes.shape({
    url: PropTypes.string,
  }),
  link: PropTypes.string,
}
