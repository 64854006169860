import { images } from "../images"

export const en = {
    hello: {
      header:
        "Hello, my friend!",
      subheader: "I hope, that you find something interesting for yourself!",
    },
    aboutMe: {
      header: "Let's meet",
      subheader: "My name is Bartek, i created this places to collect and share my notes, thinks and experiences about IT. I hope that you will find interesting staffs from here.",
      paragraphes: [
        {
          id: 1,
          content:
            "Actually I create a websites, co-create a web app about helthly nutrition, I am looking for new challenges, so this blog exactly is!",
        },
        {
          id: 2,
          content:
            "I would like to invite you to read my notes.",
        },
      ],
    },
    myFreeTime: {
      header: "What I do in my free time?",
      activities: [
        {
          id: 1,
          img: images.calm,
          title: "I train my mind",
          paragraph:
            " - read books książki, create new projects, develop my ideas.",
        },
        {
          id: 2,
          img: images.guitarist,
          title: "I play the guiterGram na gitarze",
          paragraph: " - I was even guitar teacher somewhen! ",
        },
        {
          id: 3,
          img: images.gym,
          title: "I care about my coditionDbam o kondycję",
          paragraph:
            " - a healthy mind in a healthy body.",
        },
      ],
    },
    blog: {
      header: "How is it going?",
      postTitle: "title",
      postDescription: "description",
    },
    contact: {
      header: "Contact",
      subheader: "Text me or check my another profiles in social medias",
      img: images.hej1,
      contactPictures: [
        {
          id: 2,
          src: images.linkedin,
          link: "https://www.linkedin.com/in/sroka-bartosz/",
        },
        { id: 3, src: images.github, link: "https://github.com/bisroka" },
      ],
    },
    footer: {
      footerContent: "Created by Bartosz Sroka",
    },
    buttons: {
      navButtons: [
        { id: 1, content: "Start", link: "/" },
        { id: 2, content: "About Me", link: "/aboutMe/" },
        { id: 3, content: "Blog", link: "/blog/" },
        { id: 4, content: "Contact", link: "/contact/" },
      ],
      showPostButton: "More...",
      loadMorePostButton: "Load more",
      checkAllPosts: "Check all posts"
    },
  }